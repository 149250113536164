import { useQuery } from '@apollo/client';
import React from 'react'
import { GetProductsQuery } from '../../graphql/products';
import { Link } from 'react-router-dom';
import Appbar from '../../components/appbar/component';
import VSpacer from '../../components/v_spacer/component';
import Experience from '../../components/experience/component';
import AboutProducts from '../../components/about_products/component';
import AvailableProducts from '../../components/available_products/component';
import Shop from '../shop/shop';

const HomePage = () => {

    // get products
    // const { loading, data, error, refetch, fetchMore, } = useQuery(GetProductsQuery, {
    //     variables: {
    //         input: {},
    //         offset: 0,
    //         limit: 30,
    //     },
    //     notifyOnNetworkStatusChange: true,
    // })

    // console.log("loading, data, error, ", loading, data, error,);
    
    // if( loading ) {

    //     return (
    //         <h1>
    //             Loading
    //         </h1>
    //     )
    // }
    // if( error ) {

    //     return (
    //         <h1>
    //             error {error.message}
    //         </h1>
    //     )
    // }

    // return (
    //     <h1>Got Data</h1>
    // )
    // const products = data['getProducts']
    // return (
    //     <div>

    //         {
    //             products.map((product)=> {

    //                 return (
    //                     <a href={`product/${product?._id}`} key={product?._id}>
    //                         <div style={{ marginBottom: "2rem", border: "1px solid green", }}>
    //                             {JSON.stringify(product)}
    //                         </div>
    //                     </a>
    //                 )
    //             })
    //         }
            
    //     </div>
    // )

    return (
        <div className='page'>

            {/* appbar */}
            <Appbar />
            <VSpacer space={8} />

            {/* experience */}
            <Experience />
            <VSpacer space={8} />

            {/* what we do */}
            <AboutProducts />
            <VSpacer space={8} />

            <AvailableProducts />
            <VSpacer space={4} />

            <Shop />

        </div>
    )
}

export default HomePage