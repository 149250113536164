"use client"

import { useQuery } from '@apollo/client'
import { CircularProgress, Grid, Skeleton } from '@mui/material'
import { useWindowWidth } from '@react-hook/window-size'
import { motion } from 'framer-motion'
import React, { useContext, useEffect, useState } from 'react'
import { ProfileContext } from '../../providers/profile'
import { ISelectedFilters } from '../../models/filters'
import { GetProductsQuery } from '../../graphql/products'
import { CarBrandsAndModels } from '../../utility/form_data'
import Filters from './filters'
import VSpacer from '../../components/v_spacer/component'
import { Link } from 'react-router-dom'
import ProductCard from '../../components/product_card/component'
import LoadMore from './load_more'

// utility


// graphql

// models

// providers

// components
import Error from "../../components/error/component"
import Appbar from '../../components/appbar/component'
import Shop from './shop'



const ShopPage = () => {
    
    
    return (
        <div className='page'>

            {/* appbar */}
            <Appbar />
            <VSpacer space={8} />

            <Shop />

        </div>
    )
}


export default ShopPage