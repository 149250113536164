"use client"
import { Skeleton } from '@mui/material'
import { motion } from 'framer-motion'
import React from 'react'
import Appbar from '../../components/appbar/component'
import { containerVariants, itemVariants } from '../../styles/variants'
import VSpacer from '../../components/v_spacer/component'

// styles

// components


const Loading = () => {
    return (
        <motion.div
            className='page page_loading'
            variants={containerVariants}
            animate='animate'
            initial='initial'
            style={{
                // backgroundColor: 'thistle',
            }}
        >

            {/* appbar */}
            <Appbar />
            <VSpacer spaceE='12vh' />

            {/* image */}
            <motion.div variants={itemVariants}>
                <Skeleton variant='rounded' height={360} />
            </motion.div>
            <VSpacer space={2} />

            <motion.div variants={itemVariants}>
                <Skeleton variant='rounded' width={240} height={48} />
            </motion.div>
            <VSpacer space={2} />

            <motion.div variants={containerVariants} className='row ca_center'>

                <motion.div variants={itemVariants}>
                    <Skeleton variant='rounded' width={120} height={36} style={{ marginRight: "1rem", }} />
                </motion.div>

                <motion.div variants={itemVariants}>
                    <Skeleton variant='rounded' width={90} height={36} />
                </motion.div>
            </motion.div>
            <VSpacer space={2} />

            <motion.div variants={itemVariants}>
                <Skeleton variant='rounded' width={80} height={32} />
            </motion.div>
            <VSpacer space={1} />

            <motion.div variants={itemVariants}>
                <Skeleton variant='rounded' width={300} height={48} />
            </motion.div>
            <VSpacer space={2} />

            <motion.div variants={itemVariants}>
                <Skeleton variant='rounded' width={80} height={32} />
            </motion.div>
            <VSpacer space={1} />

            <motion.div variants={itemVariants}>
                <Skeleton variant='rounded' width={300} height={48} />
            </motion.div>
            <VSpacer space={2} />

            <motion.div variants={itemVariants}>
                <Skeleton variant='rounded' width={"100%"} height={48} />
            </motion.div>
            <VSpacer space={2} />

            <motion.div variants={containerVariants} className='row ca_center ma_space_btn'>

                <motion.div variants={itemVariants}>
                    <Skeleton variant='rounded' width={"32vw"} height={48} style={{ marginRight: "1rem", }} />
                </motion.div>

                <motion.div variants={itemVariants}>
                    <Skeleton variant='rounded' width={"32vw"} height={48} />
                </motion.div>
            </motion.div>
            <VSpacer space={2} />

        </motion.div>
    )
}

export default Loading