import { gql } from "@apollo/client";



// get profile query
export const GetProductQuery = gql`
  query getProduct($id: String!) {
    getProduct( id: $id ) {
        
        _id
        name
        description
        
        brand
        model

        years
        
        variants {
          name
          price
          discount
          image
        }

        deliveryDays

    }
  }
`;




// get profile query
export const GetProductsQuery = gql`
  query getProducts($input: SearchProductsInput!, $limit: Float, $offset: Float) {
    getProducts( input: $input, limit: $limit, offset: $offset ) {
        
        _id
        name
        description
        
        brand
        model

        years
        
        variants {
          name
          price
          discount
          image
        }

        deliveryDays

    }
  }
`;

