import { useQuery, } from '@apollo/client';
import React, { useEffect, useState } from 'react'
import {useParams} from "react-router-dom";
import { GetProductQuery } from '../../graphql/products';
import IProduct from '../../models/product';
import { useAtom } from 'jotai';
import ICartItem from '../../models/cart';
import { cartAtom } from '../../store/cart';
import Loading from './loading';
import { motion } from 'framer-motion';
import { containerVariants, itemFadeInVariants, itemVariants } from '../../styles/variants';
import VSpacer from '../../components/v_spacer/component';
import Appbar from '../../components/appbar/component';
import Error from '../../components/error/component';
import { Button, Fab, Grid } from '@mui/material';
import { MdError, MdShoppingCart } from 'react-icons/md';
import clsx from 'clsx';
import { BsCreditCard2Back } from 'react-icons/bs';
import { IoMdRefresh } from "react-icons/io";


import "./page.scss"

const ProductDetails = () => {
    const {id} = useParams();
    const [ cart, setCart ] = useAtom(cartAtom)
    const [cartItem, setCartItem] = useState<ICartItem>({ _id: id, quantity: 1, })
    // const [products, setProducts] = useState<IProduct[]>([])
    // const [years, setYears] = useState<number[]>([])

    console.log("id ", id);

    // get products for the given category
    const { loading, data, error, refetch, } = useQuery(GetProductQuery, { variables: { id, } })
    console.log("loading, data, error,  ", loading, data, error, );


    useEffect(()=> {

        console.log('====================================');
        console.log("product changed something");
        console.log('====================================');

        if( !data ) {

            console.log("no data");
            return
        }

        if( !loading && ( !data || !data['getProduct'] ) ) {
            console.log("No Car model ", product?.model)
            return
        }

        let prod: IProduct = data['getProduct']
        setCartItem({
            _id: prod?._id,
            name: prod?.name,
            model: prod?.model,
            brand: prod?.brand,
            year: prod?.years[0],
            quantity: 1,
            variant: prod?.variants[0],
        })
    }, [ data ])


    const addItemToCart = ()=> {
        let exists = cart.items.find((item)=> item.variant.name == cartItem?.variant?.name)

        let newItems = []
        if( exists ) {

            newItems = cart.items.map((item)=> {

                if( item?._id == cartItem?._id && item.variant.name == cartItem?.variant?.name ) {
    
                    return {
                        ...item,
                        quantity: item?.quantity + 1,
                    }
                }
    
                return item
            })
        } else {

            newItems = [ ...cart.items, cartItem ]
        }
        // let newItems = cart.items.map((item)=> {

        //     if( item?._id == cartItem?._id && item.variant.name == cartItem?.variant?.name ) {

        //         return {
        //             ...item,
        //             quantity: item?.quantity + 1,
        //         }
        //     }

        //     return item
        // })

        console.log('====================================');
        console.log("newItems ", newItems);
        console.log('====================================');

        setCart((_)=> {

            return { items: newItems }
        })
    }

    const addItemToCartAndCheckout = ()=> {
        addItemToCart()

        // router.push(Routes.cart)
    }


    console.log("loading, data, error ", loading, data, error)
    const SECTION_SPACE = 2.4
    if( loading ) {

        return (
            <Loading />
        )
    }
    const product = data ? data['getProduct'] : null
    if( error || product == null ) {

        return (
            <motion.div
                className='page'
                variants={containerVariants}
                animate='animate'
                initial='initial'
                style={{
                    // backgroundColor: 'thistle',
                }}
            >
    
                {/* appbar */}
                <Appbar />
                <VSpacer spaceE='16vh' />

                {/* error */}
                <Error
                    icon={<MdError size={64} />}
                    title='Product Not Found'
                    text='The product you are searching was not found. Please visit our home page or click refresh to try again.'
                    cta={
                        <>
                            <VSpacer space={.5} />
                            <Button variant='contained' startIcon={<IoMdRefresh />} onClick={()=> refetch({})} disableElevation>
                                Refresh
                            </Button>
                        </>
                    }
                />

            </motion.div>
        )
    }
    return (
        <motion.div
            className='page'
            variants={containerVariants}
            animate='animate'
            initial='initial'
            style={{
                // backgroundColor: 'thistle',
            }}
        >

            {/* appbar */}
            <Appbar />
            <VSpacer spaceE='15vh' />

        
            <motion.div
                variants={containerVariants}

                style={{
                    // backgroundColor: 'thistle',
                }}
            >

                <Grid container spacing={4}>

                    <Grid item xs={12} lg={6}>
                        {/* image */}
                        <motion.div variants={containerVariants} className='product_details__image_container'>
                            <motion.img
                                src={ cartItem?.variant?.image }
                                className='product_details_image'
                                variants={itemFadeInVariants}
                            />
                        </motion.div>
                        <VSpacer space={1} />
                    </Grid>

                    <Grid item xs={12} lg={6}>

                        {/* name */}
                        <motion.p className='title_4 bold' variants={itemVariants}>
                            { product?.name }
                        </motion.p>
                        <VSpacer space={1} />

                        {/* brand model */}
                        <motion.p className='title_7 bold' variants={itemVariants}>
                            { product?.brand }, { product?.model }
                        </motion.p>
                        <VSpacer space={1} />
                    

                        {/* year */}
                        <motion.p className='title_7 bold' variants={itemVariants}>
                            { product?.years.length > 1 ? "Select Year" : "Year" }
                        </motion.p>
                        <VSpacer space={1} />

                        {/* year */}
                        {
                            product?.years?.length === 1 &&
                                <motion.p variants={itemVariants}>
                                    {product?.years[0]}
                                </motion.p>
                        }
                        
                        {
                            product?.years?.length > 1 &&

                                <div
                                    className='row category_products__models_grid'
                                    style={{
                                        // backgroundColor: 'thistle',
                                        gap: '1rem 1.2rem',
                                    }}
                                >
                                {
                                    product?.years?.map((yr)=> {

                                        return (
                                            <motion.div
                                                key={yr}
                                                onClick={
                                                    ()=> setCartItem((state)=> {
                                                        return { ...state, year: yr, }
                                                    })
                                                }
                                                variants={itemVariants}
                                                className={
                                                    clsx({
                                                        'category_products__models_grid_item_selected': cartItem?.year == yr,
                                                        'category_products__models_grid_item': true,
                                                    })
                                                }
                                                // className='category_products__models_grid_item'
                                            >
                                                {yr}
                                            </motion.div>
                                        )
                                    })
                                }
                                </div>
                        }
                        <motion.div
                            className='row category_products__models_grid'
                            variants={containerVariants}
                            style={{
                                // backgroundColor: 'thistle',
                                gap: '1rem 1.2rem',
                            }}
                        >
                            {/* {
                                loading &&
                                    new Array(10).fill(0).map((_, index)=> {
                                    
                                        return (
                                                <Skeleton
                                                    key={index}
                                                    variant="rounded"
                                                    width={Math.random() * 200 + 10}
                                                    height={32}
                                                />
                                        )
                                    })
                            } */}
                            {/* {
                                years.map((year)=> {

                                    return <div key={brand} />
                                    return (
                                        <CategoryModelChip
                                            key={year}
                                            category={year.toString()}
                                            selected={ year == product.year }
                                            onClick={
                                                ()=> {

                                                    const price = products.find((product)=> product.year == year)?.price
                                                    console.log("found ", price)
                                                    setProduct({ ...product, year, price, })
                                                }
                                            }
                                        />
                                    )
                                })
                            } */}
                        </motion.div>
                        <VSpacer space={SECTION_SPACE} />


                        {/* variant */}
                        <motion.p className='title_7 bold' variants={itemVariants}>
                            { product?.years.length > 1 ? "Selected Type" : "Type" }
                        </motion.p>
                        {
                            product?.years.length > 1 &&
                                <motion.small className='product_details__variant_chooser_instruction' variants={itemVariants}>
                                    When you change this please check the updated product image.
                                </motion.small>
                        }
                        <VSpacer space={1} />

                        {/* year */}
                        {
                            product?.variants?.length === 1 &&
                                <motion.p variants={itemVariants}>
                                    {product?.variants[0]?.name}
                                </motion.p>
                        }
                        
                        {
                            product?.variants?.length > 1 &&

                                <div
                                    className='row category_products__models_grid'
                                    style={{
                                        // backgroundColor: 'thistle',
                                        gap: '1rem 1.2rem',
                                    }}
                                >
                                {
                                    product?.variants?.map((variant)=> {

                                        return (
                                            <motion.div
                                                key={variant?.name}
                                                onClick={
                                                    ()=> setCartItem((state)=> {
                                                        return { ...state, variant, }
                                                    })
                                                }
                                                variants={itemVariants}
                                                className={
                                                    clsx({
                                                        'category_products__models_grid_item_selected': cartItem?.variant?.name == variant?.name,
                                                        'category_products__models_grid_item': true,
                                                    })
                                                }
                                                // className='category_products__models_grid_item'
                                            >
                                                {variant?.name}
                                            </motion.div>
                                        )
                                    })
                                }
                                </div>
                        }
                        <VSpacer space={SECTION_SPACE} />
                        
                        {/* price */}
                        {
                            product &&
                                <motion.div className='row ma_space_btn ca_center' variants={containerVariants}>
                                    
                                    {/* price */}
                                    <motion.p className='title_8 bold' variants={itemVariants}>
                                        Price
                                    </motion.p>
                
                
                                    <motion.p className='title_8 bold' variants={itemVariants}>
                                        { cartItem?.variant?.price }
                                    </motion.p>
                                    
                                </motion.div>
                        }
                        <VSpacer space={SECTION_SPACE * 1.5} />

                        {/* add */}
                        <motion.div className='row ma_space_btn ca_center' style={{ gap: '.5rem' }} variants={containerVariants}>

                            {/* add to cart */}
                            <Fab
                                sx={{ m: { width: '320px' } }}
                                variant="extended"
                                color="secondary"
                                style={{
                                    textTransform: 'none',
                                    borderRadius: 10,
                                }}
                                onClick={addItemToCart}
                                // disabled={
                                //     !product.name || !product.brand || !product.model || !product.year
                                // }
                            >
                                Add To Cart
                                <MdShoppingCart fontSize={20} style={{ marginLeft: '1rem' }} />
                            </Fab>


                            {/* checkout */}
                            <Fab
                                sx={{ m: { width: '320px' } }}
                                variant="extended"
                                color="primary"
                                style={{
                                    textTransform: 'none',
                                    borderRadius: 10,
                                }}
                                onClick={addItemToCartAndCheckout}
                                // disabled={
                                //     !product.name || !product.brand || !product.model || !product.year
                                // }
                            >
                                Checkout
                                <BsCreditCard2Back style={{ marginLeft: '1rem' }} />
                            </Fab>

                        </motion.div>
                        <VSpacer space={SECTION_SPACE * 2} />

                    </Grid>
            
                </Grid>

            </motion.div>
        </motion.div>
    )
    if( loading ) {

        return (
            <h1>
                Loading
            </h1>
        )
    }
    if( error ) {

        return (
            <h1>
                error {error.message}
            </h1>
        )
    }
    return (
        <div>
            ProductDetails
        
            <h1>{JSON.stringify(data['getProduct'])}</h1>
        </div>
    )
}

export default ProductDetails