import { Grid } from '@mui/material'
import React from 'react'

// components
import AboutCard from './about_card'
import { FaAward, FaStar } from 'react-icons/fa'
import { BiSolidBadgeCheck } from "react-icons/bi";

const AboutProducts = () => {
    const info = [
        {
            title: "Quality",
            text: "Our products are quality, Our products are quality.",
            icon: <FaStar color="#E4A253" size={40} />,
            bgColor: "#E4A253",
        },
        {
            title: "Authentic",
            text: "Our products are quality, Our products are quality.",
            icon: <BiSolidBadgeCheck color="teal" size={40} />,
            bgColor: "teal",
        },
        {
            title: "New",
            text: "Our products are quality, Our products are quality, Our products are quality.",
            icon: <FaAward color="tomato" size={40} />,
            bgColor: "tomato",
        },
    ]
    return (
        <div>
            
            <div className='row_wrapped ma_space_btn ca_center' style={{ gap: "1rem", }}>

                {
                    info.map((data, index)=> {

                        return (
                            <AboutCard key={index} {...data} />
                        )
                        return (
                            <Grid key={index} item xs={12} sm={4} lg={3}>
                                <AboutCard {...data} />
                            </Grid>
                        )
                    })
                }

            </div>

        </div>
    )
}

export default AboutProducts