import React from 'react'
import { motion } from 'framer-motion'
import { BsAwardFill } from 'react-icons/bs'

// components
import VSpacer from '../v_spacer/component'

// styles

import "./about_card.scss"
import { containerVariants, itemVariants } from '../../styles/variants'

type Props = {
    title?: string
    text?: string
    icon: React.ReactNode
    bgColor: string
}
const AboutCard = ({ title, text, icon, bgColor, }: Props) => {
    return (
        <motion.div variants={containerVariants} initial="initial" animate="animate" className='column ca_center about_card' style={{ backgroundColor: bgColor, }}>
            
            <motion.div variants={itemVariants} className='row ma_center ca_center about_card__icon_container'>
                {icon}
            </motion.div>
            <VSpacer space={.5} />

            <motion.p variants={itemVariants} className='title_7'>
                {title}
            </motion.p>
            <VSpacer space={.5} />

            <motion.p className='text_center about_card_text' variants={itemVariants}>
                {text}
            </motion.p>

        </motion.div>
    )
}

export default AboutCard