import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import ProfileProvider from './providers/profile';


import { ToastContainer } from 'react-toastify';
import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from '@apollo/client';
import { getLocalProfile } from './services/profile';
import { setContext } from '@apollo/client/link/context';
import { SERVER_URL } from './utility/constants';
import { ThemeProvider } from '@mui/material';
import theme from './styles/theme';

import { Provider as JotaiProvider } from 'jotai'
import ProfileProvider from './providers/profile';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const httpLink = createHttpLink({
  uri: `${SERVER_URL}/api`,
  // include works for development because server and ui are in different domains
  // in prod, same-origin should be used instead
  credentials: 'same-origin', // 'include', // 'same-origin'
})


const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const profile = getLocalProfile()
  // console.log("token ", profile?.accessToken)
  // console.log("profile ", )
  
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      // authorization: profile?.accessToken ? `Bearer ${profile?.accessToken}` : "",
    }
  }
})


const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {

        fields: {

          getProducts: {

            // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: false,
    
            // Concatenate the incoming list items with
            // the existing list items.
            merge(existing = [], incoming) {
                return [...existing, ...incoming];
            },
            

          },

        },
      },
    },
  }),
})

root.render(
  <React.StrictMode>
    <JotaiProvider>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <ProfileProvider>
            <App />
          </ProfileProvider>
          </ApolloProvider>
      </ThemeProvider>
    </JotaiProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
