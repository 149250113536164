import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import HomePage from './pages/home/page';
import ProductDetails from './pages/product/page';
import ShopPage from './pages/shop/page';


function App() {

  const router = createBrowserRouter([
    {
      path: "product/:id",
      element: <ProductDetails />,
    },
    {
      path: "/shop",
      element: <ShopPage />,
    },
    {
      path: "shop/product/:id",
      element: <ProductDetails />,
    },
    {
      path: "/",
      element: <HomePage />,
    },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
      {/* <HomePage /> */}
    </div>
  );
}

export default App;


// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link
// } from "react-router-dom";
// import HomePage from "./pages/home/page";
// import { useQuery } from "@apollo/client";
// import { GetProductsQuery } from "./graphql/products";


// function App() {

//     // get products
//     const { loading, data, error, refetch, fetchMore, } = useQuery(GetProductsQuery, {
//       variables: {
//           input: {},
//           offset: 0,
//           limit: 30,
//       },
//       notifyOnNetworkStatusChange: true,
//   })

//   console.log("loading, data, error, ", loading, data, error,);
  

//   return (
//     <div>
//       <h1>home</h1>
//       {/* <HomePage /> */}
//     </div>
//   );
// }

// export default App;