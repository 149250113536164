"use client"
import { motion } from 'framer-motion'
import React from 'react'
import { FaRegHourglass } from 'react-icons/fa'

// components

// styles
import "./component.scss"
import VSpacer from '../v_spacer/component'
import { containerVariants, itemVariants } from '../../styles/variants'

type Props = {
    icon?: React.ReactNode
    cta?: React.ReactNode
    title: string
    text: string
}
const Error = ({ title, text, icon, cta, }: Props) => {
    return (
        <motion.div variants={containerVariants} animate="animate" initial="initial" className='column ma_center ca_center error_loading'>
            
            {/* icon */}
            <motion.div variants={itemVariants}>
                { icon || <FaRegHourglass size={40} /> }
            </motion.div>
            <VSpacer space={.5} />

            <motion.p variants={itemVariants} className='title_7'>
                {title}
            </motion.p>
            <VSpacer space={.5} />

            <motion.p variants={itemVariants} className='text_center error_loading__text'>
                { text }
            </motion.p>
            <VSpacer space={.5} />

            {cta}

        </motion.div>
    )
}

export default Error